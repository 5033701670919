body {
  background-color: #000000;
  color: #ffffff; /* White text for contrast */
  font-family: 'Courier New', monospace; /* Monospace font for that classic hacker look */
  margin: 0;
  padding: 0;
}

/* Header styling */
header {
  text-align: center;
  padding: 50px;
  background-color: #111111;
  color: #ff0000; /* Devilish red for the header text */
  text-shadow: 0 0 10px #ff0000; /* Glow effect */
}

/* Navigation styling */
nav ul {
  display: flex;
  justify-content: center;
  list-style: none;
  background-color: #222222;
  padding: 10px;
}

nav ul li button {
  background: none;
  color: #ff0000; /* Devilish red for the buttons */
  border: 1px solid #ff0000;
  padding: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
}

nav ul li button:hover {
  background-color: #ff0000;
  color: #000000;
  box-shadow: 0 0 15px #ff0000; /* Glow effect on hover */
}

/* Section styling */
section {
  padding: 20px;
  margin: 20px;
  background-color: #1a1a1a;
  border: 2px solid #ff0000; /* Devilish red border */
  box-shadow: 0 0 10px #ff0000; /* Glow effect */
}

h2 {
  color: #ff0000; /* Devilish red headings */
  text-shadow: 0 0 5px #ff0000; /* Glow effect */
}

/* Footer styling */
footer {
  text-align: center;
  padding: 20px;
  background-color: #111111;
  color: #ffffff; /* White for footer text */
}

/* Links styling */
a {
  color: #ff0000; /* Devilish red links */
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  color: #ffffff; /* White on hover */
  text-shadow: 0 0 5px #ffffff; /* Glow effect on hover */
}

/* Social icons styling */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px; /* This adds space between the icons */
}

.social-icons a {
  color: #ff0000;
  font-size: 24px;
  transition: all 0.3s ease;
}

.social-icons a:hover {
  color: #ffffff;
  text-shadow: 0 0 10px #ffffff; /* Glow effect on hover */
}

/* Add a glitch effect to the main heading */
@keyframes glitch {
  0% {
    text-shadow:
      0.05em 0 0 #ffffff,
      -0.05em -0.025em 0 #ff0000;
  }
  14% {
    text-shadow:
      0.05em 0 0 #ffffff,
      -0.05em -0.025em 0 #ff0000;
  }
  15% {
    text-shadow:
      -0.05em -0.025em 0 #ffffff,
      0.025em 0.025em 0 #ff0000;
  }
  49% {
    text-shadow:
      -0.05em -0.025em 0 #ffffff,
      0.025em 0.025em 0 #ff0000;
  }
  50% {
    text-shadow:
      0.025em 0.05em 0 #ffffff,
      0.05em 0 0 #ff0000;
  }
  99% {
    text-shadow:
      0.025em 0.05em 0 #ffffff,
      0.05em 0 0 #ff0000;
  }
  100% {
    text-shadow:
      -0.025em 0 0 #ffffff,
      -0.025em -0.025em 0 #ff0000;
  }
}

h1 {
  animation: glitch 500ms infinite;
}
